import { reactive, ref } from "vue";
import { list } from "@/api/info/refundRecordApi";
import useMessage from "@/hooks/useMessage";
import config from "../../config";
const useRefundRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);

  const refundRecordVo = reactive({
    payOrderId: "",
    payState: "",
    shouldPayAmount: "",
    paidAmount: "",
    payTime: "",
    phone: "",
    nickName: "",
  });

  const auditRecordVo = reactive({
    auditState: "",
    auditedBy: "",
    auditTime: "",
    remark: "",
    reason: ""
  });

  const auditVo = reactive({
    auditState: -1,
    remark: "",
  });

  const refundVo = reactive({
    payOrderId: "",
    refundAmount: "",
    reason: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "订单号",
      align: "center",
      dataIndex: "payOrderId",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
    {
      title: "联系电话",
      align: "center",
      dataIndex: "phone",
    },
    {
      title: "支付类型",
      align: "center",
      dataIndex: "payChannel",
      slots: { customRender: "payChannel" },
    },
    {
      title: "实付金额（元）",
      align: "center",
      dataIndex: "paidAmount",
      slots: { customRender: "paidAmount" },
    },
    {
      title: "支付时间",
      align: "center",
      dataIndex: "payTime",
    },
    {
      title: "退款金额（元）",
      align: "center",
      dataIndex: "refundAmount",
      slots: { customRender: "refundAmount" },
    },
    {
      title: "申请退款时间",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "退款状态",
      align: "center",
      dataIndex: "state",
      slots: { customRender: "state" },
    },
    {
      title: "审核状态",
      align: "center",
      dataIndex: "auditState",
      slots: { customRender: "auditState" },
    },
    {
      title: "审核说明",
      align: "center",
      dataIndex: "remark",
    },
    {
      title: "完成时间",
      align: "center",
      dataIndex: "refundTime",
    },
    {
      title: "操作",
      align: "center",
      slots: { customRender: "operation" },
    },
  ];
  const getChannel = type => {
    switch (type) {
      case config.payChannel.weChat:
        return "微信";
      case config.payChannel.Alipay:
        return "支付宝";
      case config.payChannel.UnionPay:
        return "银联";
      case config.payChannel.cash:
        return "现金";
      case config.payChannel.other:
        return "其他";
      default:
        return "其他";
    }
  };
  const moneyZ = val => {
    if (typeof val !== "undefined") {
      return val * 100;
    }
    return 0;
  };
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const timeF = m => {
    if (typeof m !== "undefined") {
      return (m / 60).toFixed(0);
    }
    return "";
  };
  const getParkingTime = t => {
    if (typeof t !== "undefined") {
      var h = ((t - (t % 3600)) / 3600).toFixed(0);
      var s = ((t % 3600) / 60).toFixed(0);
      return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
    }
    return "";
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      payOrderId: params.payOrderId,
      auditTimeArr: params.auditTimeArr ? params.auditTimeArr.toString() : [],
    };
    try {
      let res = await list(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    payOrderId: "",
    auditTimeArr: [],
  });
  return {
    loading,
    params,
    dataList,
    total,
    refundRecordVo,
    refundVo,
    auditVo,
    auditRecordVo,
    columns,
    loadData,
    money,
    timeF,
    getParkingTime,
    getChannel,
    moneyZ,
  };
};
export default useRefundRecord;
