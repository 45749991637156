import axios from "axios";

/**
 * 获取退款列表
 * @param params
 * @param payState
 * @param page
 * @param pageSize
 * @returns {Promise<AxiosResponse<T>>}
 */
export const list = (params, page, pageSize) => {
  return axios.get("/refundOrder/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

/**
 * 退款审核
 * @param refundId
 * @param vo
 * @returns {Promise<AxiosResponse<T>>}
 */
export const auditRefund = (refundId, vo) => {
  return axios.put(`/refundOrder/${refundId}/audit`, vo);
};

export const addRefund = vo => {
  return axios.post(`/refundOrder/save`, vo);
};

export const detail = refundOrderId => {
  return axios.get(`/refundOrder/${refundOrderId}/detail`);
};
